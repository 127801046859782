var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box" }, [
      _c("div", { staticClass: "title" }, [_vm._v("雀诊互联网医院用户协议")]),
      _vm._v(" 尊敬的用户："),
      _c("br"),
      _vm._v(" 欢迎使用雀诊！"),
      _c("br"),
      _vm._v(" 【协议说明】本协议是您与雀诊之间关于使用本服务所订立的协议。"),
      _c("br"),
      _vm._v(
        " 【审慎阅读】您在申请注册流程中点击同意本协议之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。如您对协议有任何疑问，可向雀诊客服咨询。如您未满18周岁，或以其他形式被限制民事行为能力，请在监护人的陪同下阅读本协议。"
      ),
      _c("br"),
      _vm._v(
        " 【签约动作】您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容，并与雀诊达成一致，成为雀诊平台用户。您的注册行为将被认为是对本协议全部条款无保留的接受和遵守。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序。"
      ),
      _c("br"),
      _vm._v(" 【词汇定义】本文中所用词汇定义如下："),
      _c("br"),
      _vm._v(
        " 雀诊：指包括雀诊小程序、雀诊移动客户端（APP）及其他现在或将来推出的基于雀诊服务的平台。"
      ),
      _c("br"),
      _vm._v(
        " 用户：指阅读并同意本协议内容，经过雀诊注册程序成为雀诊各项服务使用者的单位或个人。（以下更多称为“您”）"
      ),
      _c("br"),
      _vm._v(
        " 雀诊管理规定：指包括本协议在内的，由用户签署，或由雀诊在明显位置展示的，具有规范用户行为作用的各类规定、提示、声明文件。"
      ),
      _c("br"),
      _vm._v(" 一、用户及注册："),
      _c("br"),
      _vm._v(
        " 1.1雀诊提供用户注册。您的帐号和密码应当由您自行保管，您应当对以您的帐号进行的所有活动和事件负法律责任。"
      ),
      _c("br"),
      _vm._v(
        " 1.2您注册时，在账号名称、头像和简介等注册信息中不得出现违法和不良信息，否则雀诊有权拒绝提供服务，并关闭该账号。"
      ),
      _c("br"),
      _c("br"),
      _vm._v(" 二、服务内容："),
      _c("br"),
      _vm._v(
        " 2.1雀诊服务的具体内容由雀诊经营者提供，雀诊经营者保留随时变更、中断或终止部分或全部网络服务的权利。"
      ),
      _c("br"),
      _vm._v(
        " 2.2 雀诊作为医患交流互通平台，您通过雀诊发表的各种言论（包括但不仅限于咨询问题、就医经验、感谢信等），并不代表雀诊赞同您的观点或证实其内容的真实性。"
      ),
      _c("br"),
      _vm._v(
        " 2.3 您在雀诊上获得的智能问答、医生答复、随访方案、医学文章、医疗保健信息、健康咨询建议等，均不代表雀诊赞同其观点或证实内容的真实性，以上信息不能作为您采取治疗方案的直接依据。如确有必要，您应当及时去线下医疗机构进行面对面的诊疗。"
      ),
      _c("br"),
      _vm._v(
        " 2.4 雀诊在提供网络服务时，可能会对部分服务收取一定费用，雀诊将会对收费服务给予明确的提示，如您拒绝支付此类费用，雀诊有权不提供相关服务。"
      ),
      _c("br"),
      _vm._v(
        " 2.5雀诊仅提供相关的服务，除此之外与服务有关的设备（如电脑、调制解调器及其他与接入互联网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费）均应由您自行负担。"
      ),
      _c("br"),
      _vm._v(
        " 2.6因不可抗力、网络状况、通讯线路、用户自身过错等技术原因，或其他不可控原因导致您不能正常使用雀诊服务，雀诊不承担相应责任。"
      ),
      _c("br"),
      _c("br"),
      _vm._v(" 三、用户的权利和责任："),
      _c("br"),
      _vm._v(
        " 3.1 您有权利拥有自己在雀诊的用户名及密码，并有权利使用自己的用户名及密码随时登陆雀诊服务。"
      ),
      _c("br"),
      _vm._v(
        " 3.2您不得以任何形式转让或授权他人使用自己的雀诊用户名，亦不得盗用他人帐号，由以上行为造成的后果由用户自行承担。"
      ),
      _c("br"),
      _vm._v(
        " 3.3 用户有权根据雀诊管理规定发布、获取信息，进行医患互通交流等。"
      ),
      _c("br"),
      _vm._v(
        " 3.4您必须遵守有关卫生健康相关法律法规及国家关于互联网信息发布的相关法律法规，您对自己在雀诊上发布的信息承担责任，您不得发布违法信息，不得恶意评价其他用户。您承诺自己在使用雀诊时实施的所有行为均遵守国家法律、法规和雀诊管理规定以及社会公共利益或公共道德。如您违反上述任一规则，导致相应法律后果的发生，您将以自己的名义独立承担所有法律责任。如因您违反上述任一规则，对雀诊造成损害的，雀诊有权向您进行追责。"
      ),
      _c("br"),
      _vm._v(
        " 3.5 您不得将涉及医疗纠纷的问题或其它有责任争议的问题在雀诊发布，关于医疗纠纷的问题，请另行咨询律师或相关主管部门寻求援助或咨询，雀诊有权将此类信息删除。"
      ),
      _c("br"),
      _vm._v(
        " 3.6您发现其他用户有违法或违反雀诊管理规定的行为，可以向雀诊进行反映要求处理。您因雀诊展示的内容与其他用户发生纠纷的，司法部门可以要求雀诊根据法律程序提供该案件所需证据材料。"
      ),
      _c("br"),
      _c("br"),
      _vm._v(" 四、雀诊的权利和责任："),
      _c("br"),
      _vm._v(
        " 4.1雀诊将协助医患之间进行合法的交流，并提供必要的网络技术帮助；"
      ),
      _c("br"),
      _vm._v(
        " 4.2 雀诊有义务在现有技术上维护整个网络平台的正常运行，并努力提升和改进技术，使医患网上交流、互助得以顺利进行；"
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        " 4.3 雀诊仅作为医患互通信息服务的平台，不对您发布的信息的来源和正确性负责，雀诊也不参与医患交流，不对医患交流的结果承担任何责任；"
      ),
      _c("br"),
      _vm._v(
        " 4.4对于您在雀诊上的不当行为或其它任何雀诊认为应当终止服务的情况，雀诊有权随时作出删除相关信息、终止服务提供等处理，而无需征得您的同意；"
      ),
      _c("br"),
      _vm._v(
        " 4.5 雀诊没有义务对所有用户的注册数据、所有的活动行为以及与之有关的其它事项进行审查，但如存在下列情况，雀诊有权根据不同情况选择保留或删除相关信息或继续、停止对该用户提供服务，并追究相关法律责任："
      ),
      _c("br"),
      _vm._v(
        " ① 您或其它第三方通知雀诊，认为某个具体用户、具体行为、具体事项可能存在重大问题；"
      ),
      _c("br"),
      _vm._v(
        " ② 您或其它第三方向雀诊告知网络平台上有违法或不当行为的，雀诊以普通非专业医疗人员的知识水平标准对相关内容进行判别，可以明显认为这些内容或行为具有违法或不当性质的。"
      ),
      _c("br"),
      _vm._v(
        " 4.6 用户在雀诊上如与其它用户产生纠纷，请求雀诊从中予以调处，经雀诊审核后，雀诊有权向纠纷双方了解情况，并将所了解的情况互相通知对方；雀诊所作出的调处行为不具有法律效力，调处结果系由纠纷双方自愿作出，雀诊仅协助提供信息的沟通，不对调处结果承担相应法律责任。"
      ),
      _c("br"),
      _vm._v(
        " 4.7 雀诊有权对用户的注册数据及活动行为进行查阅，发现注册数据或活动行为中存在任何问题或怀疑，均有权向用户发出询问及要求改正的通知或者直接作出删除等处理；"
      ),
      _c("br"),
      _vm._v(
        " 4.8经国家生效法律文书或行政处罚决定确认用户存在违法行为，或者雀诊有足够事实依据可以认定用户存在违法或违反雀诊管理规定的行为的，雀诊有权以合理方式公布用户的违法行为；"
      ),
      _c("br"),
      _vm._v(
        " 4.9 因不可抗力（如火灾、水灾、暴动、骚乱、战争、自然灾害等）导致雀诊的服务中断或者用户数据损坏、丢失等，雀诊不承担任何责任；"
      ),
      _c("br"),
      _c("br"),
      _vm._v(" 五、服务变更、中断或终止："),
      _c("br"),
      _vm._v(
        " 5.1 因系统维护或升级的需要而需暂停网络服务，雀诊将尽可能事先进行通告。"
      ),
      _c("br"),
      _vm._v(
        " 5.2 如发生下列任何一种情形，雀诊有权解除本协议，并终止您的全部服务："
      ),
      _c("br"),
      _vm._v(
        " 5.2.1您违反国家有关法律法规或雀诊管理规定，侵害他人合法权益的；"
      ),
      _c("br"),
      _vm._v(" 5.2.2您因在雀诊上的不当行为被行政或司法机构拘留或起诉；"),
      _c("br"),
      _vm._v(" 5.2.3您丧失使用网站服务的行为能力；"),
      _c("br"),
      _vm._v(" 5.2.4您提供的个人资料不真实；"),
      _c("br"),
      _vm._v(" 5.2.5您盗用他人账户、发布违禁信息、骗取他人财物的；"),
      _c("br"),
      _vm._v(" 5.2.6您传播虚假信息，歪曲事实，经查证属实的；"),
      _c("br"),
      _vm._v(" 5.2.7其它雀诊认为需要终止服务的情况。"),
      _c("br"),
      _vm._v(
        " 除前款所述情形外，雀诊保留在不事先通知您的情况下随时中断或终止部分或全部网络服务的权利，对于服务的中断或终止而造成您的损失的，雀诊不承担任何责任。"
      ),
      _c("br"),
      _vm._v(" 5.3服务发生变更、中断、终止后，雀诊仍有以下权利："),
      _c("br"),
      _vm._v(
        " 5.3.1服务发生终止后，雀诊将您的注册数据及以前的行为记录进行匿名化处理；"
      ),
      _c("br"),
      _vm._v(
        " 5.3.2如您在服务变更、中断、终止前，在雀诊平台上存在违法行为或违反条款的行为，雀诊仍可行使本服务条款所规定的权利。"
      ),
      _c("br"),
      _vm._v(" 六、个人隐私："),
      _c("br"),
      _vm._v(
        " 尊重用户个人隐私信息的私有性是雀诊的一贯原则，雀诊将通过技术手段、强化内部管理等办法充分保护用户的个人隐私信息，除法律或有法律赋予权限的政府部门要求或事先得到用户明确授权等原因外，雀诊承诺不对外公开或向第三方透露您的个人隐私信息，或用户在使用服务时存储的非公开内容。同时，为了运营和改善雀诊的技术与服务，雀诊将可能会自行收集使用或向第三方提供用户的非个人隐私信息，这将有助于雀诊向用户提供更好的用户体验和服务质量。"
      ),
      _c("br"),
      _vm._v(
        " 您使用或继续使用我们的服务，即意味着同意我们按照《用户隐私保护政策》来收集、使用、储存和分享您的相关信息。详情请参见《用户隐私保护政策》。"
      ),
      _c("br"),
      _c("br"),
      _vm._v(" 七、免责声明："),
      _c("br"),
      _vm._v(
        " 7.1 雀诊不承诺网络服务一定能满足您的要求，也不承诺网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作承诺。"
      ),
      _c("br"),
      _vm._v(
        " 7.2 雀诊不承诺网页上设置的外部链接的准确性和完整性，同时，对于该外部链接指向的不由雀诊实际控制的任何网页上的内容，雀诊不承担任何责任。"
      ),
      _c("br"),
      _vm._v(
        " 7.3 对于因不可抗力或雀诊不能控制的原因造成的网络服务中断或其它缺陷，雀诊不承担任何责任，但将尽力减少因此而给您造成的损失和影响。"
      ),
      _c("br"),
      _c("br"),
      _vm._v(" 八、违约赔偿："),
      _c("br"),
      _vm._v(
        " 8.1 您同意保障和维护雀诊及其他用户的利益，如因您违反有关法律、法规或雀诊管理规定而给雀诊或任何其他第三人造成损失，您同意承担由此造成的损害赔偿责任。"
      ),
      _c("br"),
      _c("br"),
      _vm._v(" 九、服务条款修改："),
      _c("br"),
      _vm._v(
        " 9.1 雀诊有权根据服务情况变更、终止雀诊管理规定的各项条款，雀诊将会通过适当方式向您提示修改内容。"
      ),
      _c("br"),
      _vm._v(
        " 9.2如果您不同意雀诊管理规定所做的修改，有权停止使用网络服务。如果您继续使用网络服务，则视为您接受雀诊对服务协议相关条款所做的修改。"
      ),
      _c("br"),
      _c("br"),
      _vm._v(" 十、法律管辖及争议解决："),
      _c("br"),
      _vm._v(
        " 10.1 本服务协议的订立、执行和解释及争议的解决均应适用中国法律。"
      ),
      _c("br"),
      _vm._v(
        " 10.2 如双方就本服务协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可将争议提交上海仲裁委员会以仲裁的形式进行解决，仲裁的语言是中文，仲裁的结果是终局的。"
      ),
      _c("br"),
      _c("br"),
      _vm._v(" 十一、通知送达:"),
      _c("br"),
      _vm._v(
        " 11.1本协议项下雀诊对于您所有的通知均可通过网页公告、电子邮件、手机短信或常规的信件传送等方式进行；该等通知于发送之日视为已送达给您。"
      ),
      _c("br"),
      _vm._v(
        " 11.2您对于雀诊的通知应当通过雀诊对外正式公布的通信地址、电子邮件地址等联系信息进行送达。"
      ),
      _c("br"),
      _c("br"),
      _vm._v(" 十二、其他规定:"),
      _c("br"),
      _vm._v(
        " 12.1 本服务协议构成您与雀诊对服务之约定事项及其他有关事宜的完整协议，除服务条款规定的之外，未赋予服务条款各方其他权利。"
      ),
      _c("br"),
      _vm._v(
        " 12.2如本服务协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。"
      ),
      _c("br")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }